<template>
  <v-container id="predefinedAddress" fluid tag="section">
    <v-snackbar v-model="addFlash" :timeout="4000" top>
      <span>Predenied Address Add Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="addFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-snackbar v-model="editFlash" :timeout="4000" top>
      <span>Predenied Address Edit Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="editFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-snackbar v-model="deleteFlash" :timeout="4000" top>
      <span>Predenied Address Delete Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="deleteFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-data-table
      :headers="columns"
      :items="predefinedAddress"
      item-key="_id"
      class="elevation-1"
      hide-default-footer
      disable-pagination
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col>
              <v-text-field
                @keyup="globalSearch"
                v-model="search"
                label="Search"
                class="mx-4 mt-4"
              ></v-text-field>
            </v-col>
            <v-col>
              <router-link
                v-if="modulePermission.Create"
                :to="{ name: 'Add Predefined Address' }"
              >
                <v-btn color="primary float-right" dark class="mt-4"
                  >Add Predefined Address</v-btn
                >
              </router-link>
            </v-col>
          </v-row>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title>Confirm Delete</v-card-title
              ><v-card-text class="text-center"
                >Are you sure to delete record ?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary darken-1"
                  text
                  @click="dialogDelete = false"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary darken-1"
                  depressed
                  @click="deleteItemConfirm"
                  >Delete</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item._id`]="{ index }">
        {{ numbers[index] }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <router-link
          v-if="modulePermission.Update"
          :to="{
            name: 'Edit Predefined Address',
            params: { id: item._id },
          }"
          ><v-icon class="mr-2">mdi-pencil</v-icon></router-link
        >
        <router-link
          v-if="modulePermission.Read"
          :to="{
            name: 'View Predefined Address',
            params: { id: item._id, action: 'view' },
          }"
          ><v-icon class="mr-2">mdi-eye</v-icon></router-link
        >
        <v-icon
          v-if="modulePermission.Delete"
          @click="deletePredefinedAddress(item)"
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <div class="custom_pagination_design">
      <span>
        Showing <b>{{ startRecord }}</b> to <b>{{ endRecord }}</b> of
        <b>{{ totalRecord }}</b> Records
      </span>
      <v-pagination
        class="float-right"
        v-model="page"
        :length="totalPages"
        total-visible="7"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
        @input="handlePageChange"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
export default {
  props: ["edit", "add"],
  data() {
    return {
      moduleName: "PredefinedAddress",
      modulePermission: [],
      search: "",
      page: 1,
      predefinedAddress: [],
      loading: true,
      totalPages: 0,
      startRecord: 0,
      endRecord: 0,
      totalRecord: 0,
      numbers: [],
      addFlash: false,
      editFlash: false,
      deleteFlash: false,
      dialogDelete: false,
      deleteRecord: "",
    };
  },
  computed: {
    columns() {
      return [
        {
          text: "Actions",
          value: "actions",
          fixed: true,
          sortable: false,
          width: "150px",
        },
        { sortable: false, text: "Id", value: "_id", sortable: false },
        { sortable: false, text: "Building Name", value: "building_name" },
        { sortable: false, text: "Address", value: "address" },
        { sortable: false, text: "Latitude", value: "latitude" },
        { sortable: false, text: "Longitude", value: "longitude" },
        { sortable: false, text: "Zip Code", value: "zipcode" },
      ];
    },
  },
  methods: {
    getPredefinedAddress() {
      if (this.search == "") {
        this.search = null;
      }
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "predefinedAddress/getData/" +
            this.search +
            "?page=" +
            this.page
        )
        .then((response) => {
          if (response.status == 200) {
            this.predefinedAddress = response.data.predefinedAddress.data;
            this.loading = false;
            this.totalPages = response.data.predefinedAddress.last_page;
            this.startRecord = response.data.predefinedAddress.from;
            this.endRecord = response.data.predefinedAddress.to;
            this.totalRecord = response.data.predefinedAddress.total;
            this.numbers = [];
            for (
              let num = response.data.predefinedAddress.from;
              num <= response.data.predefinedAddress.to;
              num++
            ) {
              this.numbers.push(num);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletePredefinedAddress(data) {
      this.dialogDelete = true;
      this.deleteRecord = data._id;
    },
    deleteItemConfirm() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "predefinedAddress/delete/" +
            this.deleteRecord
        )
        .then((response) => {
          if (response.status == 200) {
            this.dialogDelete = false;
            this.deleteRecord = "";
            this.deleteFlash = true;
            this.getPredefinedAddress();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    globalSearch() {
      this.loading = true;
      this.getPredefinedAddress();
    },
    handlePageChange(value) {
      this.loading = true;
      this.page = value;
      this.getPredefinedAddress();
    },
  },
  mounted() {
    this.getPredefinedAddress();
    let permissions = JSON.parse(sessionStorage.getItem("user-permission"));
    this.modulePermission = permissions[this.moduleName];
    if (this.add) {
      this.addFlash = true;
    }
    if (this.edit) {
      this.editFlash = true;
    }
  },
};
</script>
